import React from "react";
import Header from "../components/atoms/header";
import ManualContainer from "../components/atoms/ManualContainer/ManualContainer";
import { Helmet } from "react-helmet";
function LibraryManual() {
  return (
    <>
      <Helmet>
        <title>Library Manual</title>
        <link rel="canonical" href="https://masterwizr.com/librarymanual" />
      </Helmet>
      <Header />
      <ManualContainer
        image={
          "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1673455308/Manuals/library12manual_wnbmsi.png"
        }
      />
    </>
  );
}
export default LibraryManual;
